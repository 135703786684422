.homeFisrtSection {
    width: 100%;
    height: 70vh;
    background-color: #000;
}

.homeSecondSection {
    width: 100%;
    height: auto;
    background-color: #fff;
}

@media screen and (min-width: 890px) {

    .homeFisrtSection {
        height: 100vh;
    }
}