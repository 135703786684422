.mainContainer {
    width: 100%;
    height: auto;
    padding: 30px;
    background-image: url('../assets/img/Background_2.jpg');
    background-position: center center;
    background-size: cover;
}

.mainContainer h2 {
    font-size: 24px;
    font-weight: lighter;
    color: #fff;
}

.contentContainer {
    width: 100%;
    height: auto;
    padding-top: 45px;
    display: block;
}

.contentCard {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    border: 2px solid #A567F5;
    border-radius: 5px;
    background-color: #fff;
}

.contentContainer:last-child {
    padding-top: 0;
}

.titleContainer {
    width: fit-content;
    height: 36px;
    padding: 5px 20px;
    margin: -18px auto 0 auto;
    border-radius: 5px;
    background-color: #A567F5;
    display: flex;
    align-items: center;
}

.titleContainer img {
    width: auto;
    height: 100%;
}

.titleContainer p {
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    margin-left: 10px;
}

.textContainer {
    width: 100%;
    height: calc(100% - 18px);
    padding: 25px 15px;
}

.textContainer p {
    font-size: 16px;
    text-align: center;
    line-height: 120%;
}


@media screen and (min-width: 890px) {

    .mainContainer {
        height: auto;
        padding: 80px 120px;
    }

    .mainContainer h2 {
        font-size: 36px;
    }

    .contentContainer {
        padding-top: 80px;
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .contentContainer:last-child {
        padding-top: 150px;
    }

    .contentCard {
        width: 40%;
        height: 310px;
        margin-bottom: 0;
        border-radius: 15px;
        border: 3px solid #A567F5;
    }

    .titleContainer {
        height: 58px;
        padding: 10px 20px;
        margin: -29px auto 0 auto;
        border-radius: 15px;
    }

    .titleContainer p {
        font-size: 24px;
        margin-left: 15px;
    }

    .textContainer {
        height: calc(100% - 29px);
        padding: 30px 20px;
    }

    .textContainer p {
        font-size: 20px;
    }

}