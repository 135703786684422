.mainContainer {
    width: 100%;
    height: 89%;
    margin-top: 40px;
    display: block;
    background-image: url('../assets/img/Background_1.jpg');
    background-position: center center;
    background-size: cover;
}

.contentContainer {
    width: 100%;
    height: auto;
    padding: 6% 15px 0 15px;
}

.textContainer {
    width: 90%;
    height: auto;
}

.mainText {
    width: 90%;
    height: auto;
    margin-bottom: 10px;
    padding-left: 10px;
}

.mainText h2 {
    font-size: 30px;
    font-weight: 600;
    color: #fff;
}

.subText {
    width: 100%;
    height: auto;
    padding: 15px;
    margin-top: 40px;
    background-color: rgba(2, 39, 83, 0.8);
}

.subText p {
    font-size: 14px;
    text-align: justify;
    line-height: 120%;
    color: #fff;
}

.callContainer {
    width: 100%;
    height: auto;
    margin-top: 50px;
    padding-left: 10px;
}

.callContainer button {
    width: fit-content;
    height: 35px;
    border: none;
    background-color: #00BBB5;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 15px;
}

@media screen and (min-width: 890px) {

    .mainContainer {
        margin-top: 0;
    }

    .contentContainer {
        width: 55%;
        padding: 4% 0 0 30px;
    }

    .textContainer {
        width: 100%;
    }

    .mainText {
        margin-bottom: 20px;
    }

    .mainText h2 {
        font-size: 90px;
    }

    .subText {
        padding: 20px;
        margin-top: 6%;
    }

    .subText p {
        font-size: 20px;
        line-height: 120%;
    }

    .callContainer {
        margin-top: 6%;
    }

    .callContainer button {
        height: 65px;
        border-radius: 15px;
        font-size: 30px;
    }
}