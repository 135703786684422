.container {
    width: 35px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bar {
    width: 100%;
    height: 5px;
    display: block;
    background-color: #fff;
    border-radius: 5px;
}

.background {
    width: auto;
    height: auto;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.30);
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 4;
}

.menu {
    width: 100%;
    height: auto;
    padding: 20px 0;
    background-color: #000000;
    position: fixed;
    display: flex;
    flex-direction: column;
}

.menu h2 {
    width: 100%;
    margin: 15px 0;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
}