.mainContainer {
    width: 100%;
    height: auto;
    padding: 30px 15px;
}

.mainContainer h2 {
    width: 100%;
    height: auto;
    font-size: 24px;
    font-weight: lighter;
    text-align: center;
    color: #2d2c2c;
    margin-bottom: 30px;
}

.contentContainer {
    width: 100%;
    height: auto;
    display: block;
}

.content {
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
}

.content h3 {
    font-size: 18px;
    font-weight: lighter;
    text-align: justify;
}

.servicesCard {
    width: 100%;
    min-height: 100%;
    margin-top: 15px;
    padding: 15px;
    border: 1px solid #EB3378;
    border-radius: 5px;
}

.servicesCard p {
    font-size: 16px;
    font-weight: 200;
    line-height: 150%;
}



@media screen and (min-width: 890px) {

    .mainContainer {
        height: 1000px;
        padding: 70px 30px;
    }

    .mainContainer h2 {
        font-size: 36px;
        margin-bottom: 60px;
    }

    .contentContainer {
        height: 700px;
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .content {
        width: 32%;
        margin-bottom: 0;
    }

    .content h3 {
        font-size: 28px;
    }

    .servicesCard {
        height: auto;
        margin-top: 20px;
        padding: 30px 25px;
        border-radius: 10px;
    }

    .servicesCard p {
        font-size: 24px;
    }

}