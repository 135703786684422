.mainContainer {
    width: 100%;
    height: auto;
    padding-top: 30px;
}

.mainContainer h2 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #000;
    margin-bottom: 25px;
}

.mainContainer p {
    width: 85%;
    font-size: 16px;
    font-weight: 300;
    line-height: 120%;
    text-align: center;
    margin: 0 auto 15px auto;
}

.secondContainer {
    width: 100%;
    height: auto;
    padding: 40px 50px;
    background-image: url('../assets/img/Background_4.jpg');
    background-position: center bottom;
    background-size: cover;
    opacity: 0.8;
}

.formContainer {
    width: 90%;
    max-width: 900px;
    height: auto;
    margin: 0 auto;
}

.nameInput {
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 10px;
    padding: 0 15px;
    margin-bottom: 20px;
}

.nameInput:focus {
    outline: none;
}

.inputContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputContainer input {
    width: 49%;
    height: 30px;
    border: none;
    border-radius: 10px;
    padding: 0 15px;
    margin-bottom: 20px;
}

.inputContainer input:focus {
    outline: none;
}

.textContainer {
    min-width: 100%;
    max-width: 100%;
    height: 200px;
    min-height: 180px;
    max-height: 400px;
    border: none;
    border-radius: 10px;
    padding: 15px;
}

.textContainer:focus {
    outline: none;
}

.callContainer {
    width: 100%;
    height: auto;
    margin-top: 25px;
    display: flex;
    align-items: center;
}

.callContainer button {
    width: fit-content;
    max-width: 328px;
    height: 30px;
    border: none;
    background-color: #00BBB5;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0 20px;
}

.callContainer b {
    font-size: 10px;
    color: green;
    margin-left: 15px;
}


@media screen and (min-width: 890px) {

    .mainContainer {
        padding-top: 70px;
    }

    .mainContainer h2 {
        font-size: 36px;
        margin-bottom: 50px;
    }

    .mainContainer p {
        width: 60%;
        font-size: 24px;
        margin: 0 auto 35px auto;
    }

    .secondContainer {
        padding: 80px 0;
    }

    .nameInput,
    .inputContainer input {
        height: 45px;
        border-radius: 15px;
        padding: 0 25px;
        font-size: 18px;
        margin-bottom: 40px;
    }

    .textContainer {
        min-width: 100%;
        max-width: 100%;
        border-radius: 15px;
        padding: 25px;
        font-size: 18px;
    }

    .callContainer {
        margin-top: 55px;
    }

    .callContainer button {
        height: 40px;
        border-radius: 15px;
        font-size: 22px;
        padding: 0 20px;
    }

    .callContainer b {
        font-size: 16px;
        margin-left: 25px;
    }
}