.footerContainer {
    width: 100%;
    height: 50px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContainer p {
    font-size: 10px;
    color: #fff;
}


@media screen and (min-width: 890px) {

    .footerContainer {
        height: 80px;
    }

    .footerContainer p {
        font-size: 18px;
    }
}