.mainContainer {
    width: 100%;
    height: auto;
    padding-top: 30px;
}

.mainContainer h2 {
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    color: #000;
    margin-bottom: 25px;
}

.contentContainer {
    width: 100%;
    height: auto;
    padding: 30px;
    background-color: #02112E;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contentContainer img {
    width: 46%;
    max-width: 870px;
    height: auto;
    display: block;
}

.textContainer {
    width: 45%;
    height: auto;
}

.textContainer h3 {
    font-size: 16px;
    color: #fff;
    font-weight: 300;
    text-align: center;
}


@media screen and (min-width: 890px) {

    .mainContainer {
        padding-top: 70px;
    }

    .mainContainer h2 {
        font-size: 36px;
        margin-bottom: 50px;
    }

    .contentContainer {
        height: 900px;
        padding: 80px 120px;
        background-size: cover;
    }

    .textContainer {
        width: 45%;
    }

    .textContainer h3 {
        font-size: 54px;
    }
}