.homeNavibar {
    width: 100%;
    height: 85px;
    padding: 20px 15px 15px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navibarLogo {
    width: 41.5%;
    max-width: 300px;
    height: 100%;
    display: block;
}

.navibarLogo img {
    width: auto;
    height: 100%;
    cursor: pointer;
}

.navibarContentMobile {
    width: 50%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navibarContentDesk {
    display: none;
}

.navibarBorder {
    width: auto;
    height: auto;
    border-bottom: 1px solid #eb3378;
    margin: 0 15px;
}


@media screen and (min-width: 890px) {

    .homeNavibar {
        height: 120px;
        padding: 25px 30px;
    }

    .navibarContentMobile {
        display: none;
    }

    .navibarContentDesk {
        width: 60%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navibarContentDesk h2 {
        font-size: 18px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;
    }

    .navibarBorder {
        margin: 0 30px;
    }
}

@media screen and (min-width: 1200px) {

    .navibarContentDesk {
        width: 70%;
    }

    .navibarContentDesk h2 {
        font-size: 22px;
    }
}