.mainContainer {
    width: 100%;
    height: auto;
    padding: 30px 15px 0 15px;
}

.mainContainer h2 {
    width: 100%;
    height: auto;
    font-size: 24px;
    font-weight: lighter;
    text-align: center;
    color: #2d2c2c;
    margin-bottom: 30px;
}

.mainContainer h3 {
    font-size: 16px;
    font-weight: lighter;
    color: #2d2c2c;
    margin-bottom: 25px;
}

.contentContainer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-image: url('../assets/img/Background_5.jpg');
    background-position: top center;
    background-size: cover;
}

.textContainer {
    width: 90%;
    height: auto;
    padding: 25px;
}

.textContainer p {
    font-size: 18px;
    color: #fff;
    margin-bottom: 20px;
    font-weight: lighter;
    line-height: 160%;
}

.textContainer p:last-child {
    margin-bottom: 0;
}


@media screen and (min-width: 890px) {

    .mainContainer {
        padding: 70px 30px 0 30px;
    }

    .mainContainer h2 {
        font-size: 36px;
        margin-bottom: 60px;
    }

    .mainContainer h3 {
        width: 80%;
        font-size: 28px;
        margin: 0 auto 45px auto;
    }

    .textContainer {
        width: 57%;
        padding: 80px;
    }

    .textContainer p {
        font-size: 30px;
        margin-bottom: 50px;
    }
}